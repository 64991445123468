import React from "react";

const KolTT = (param) => {
  const list = [
    { title: "李川", image: "img/tt-1.jpg" },
    { title: "陳祈", image: "img/tt-2.jpg" },
    { title: "麥克歐巴", image: "img/tt-3.jpg" },
    { title: "海苔", image: "img/tt-4.jpg" },
    { title: "池里", image: "img/tt-5.jpg" },
    { title: "Lima", image: "img/tt-6.jpg" },
    { title: "嘎慧來也", image: "img/tt-7.jpg" },
    { title: "亮亮愛化妝", image: "img/tt-8.jpg" },
    { title: "楊羚 Fion", image: "img/tt-9.jpg" },
    { title: "嚕嚕來了", image: "img/tt-11.jpg" },
    { title: "韓仲夏Summer", image: "img/tt-12.jpg" },
    { title: "富拉", image: "img/tt-13.jpg" },
    { title: "A-dark 黑達克", image: "img/tt-14.jpg" },
  ];

  return (
    <div className={param.page==="home" ? "container-fluid py-5 bg-light" : "container-fluid py-5"} id={param.page==="home" ? "koltt" : ""}>
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <div className="bg-primary mb-3 mx-auto" style={{width: '60px', height: '2px'}}></div>
          <h1 className="display-5 mb-5">獨家業務合作夥伴-海納新媒體</h1>
          <p className="mb-5">旗下擁有戲劇／街訪／美妝／舞蹈等多面向為切角的創作者，以客戶需求產出優質創意影片以達宣傳目的。</p>
        </div>
        <div className="row g-4"> 
        {list.map((kol, i) => (
          <div className="col-lg-2 col-md-4 col-sm-4 portfolio-item wow fadeInUp" data-wow-delay={`0.${Math.floor(Math.random()*8)+1}s`}>
            <div className="portfolio-inner"> <img className="w-100" src={kol.image} alt="" />
                <div className="portfolio-text text-center bg-dark mb-6">
                  <p className="text-white mb-2">{kol.title}</p>
                </div>
            </div>
          </div>
        ))}
        </div>
      </div>
    </div>
  );
};

export default KolTT;
