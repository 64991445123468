import React, { Fragment } from "react";

const ServiceIndex = () => {
  return (
    <Fragment>      
      <div className="container-fluid page-header py-5 mb-5">
        <div className="container py-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">服務項目</h1>
            <nav aria-label="breadcrumb animated slideInDown">
              <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a className="text-white" href="/">首頁</a></li>
                  <li className="breadcrumb-item text-white active">服務項目</li>
              </ol>
            </nav>
        </div>
      </div>

      <div className="container-fluid py-5 bg-light">
        <div className="container">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
              <div className="bg-primary mb-3 mx-auto" style={{width: '60px', height: '2px'}}></div>
              <h1 className="display-5 mb-5">服務內容</h1>
            </div>
            <div className="row g-4">
              <div className="col-md-4 col-lg-4 col-sm-6 wow bounceIn" data-wow-delay="0.1s">
                  <div className="h-100 bg-dark-resound p-4 p-xl-4 ius">
                    <div className="d-flex align-items-center justify-content-between mb-4 ius">
                        <div className="btn-square rounded-circle" style={{width: '60px', height: '64px'}}> <img className="img-fluid" src="img/icon/icon-1.png" alt="Icon" /> </div>
                    </div>
                    <h5 className="text-warning">打造品牌</h5>
                    <hr className="w-25" />
                    <span className="color1">從人本出發，透過行銷策略和廣告內容，協助客戶打造符合品牌或產品調性的宣傳方針與創意，樹立恰如其分的品牌形象與忠誠度。</span> </div>
              </div>
              <div className="col-md-4 col-lg-4 col-sm-6 wow bounceIn" data-wow-delay="0.3s">
                  <div className="h-100 bg-dark-resound p-4 p-xl-4 ius">
                    <div className="d-flex align-items-center justify-content-between mb-4 ius">
                        <div className="btn-square rounded-circle" style={{width: '60px', height: '64px'}}> <img className="img-fluid" src="img/icon/icon-2.png" alt="Icon" /> </div>
                    </div>
                    <h5 className="text-warning">創造曝光</h5>
                    <hr className="w-25" />
                    <span className="color1">運用各項行銷手法，如KOL、短影音、公關活動、媒體平台、口碑、廣告投放等，達到品牌快速曝光效益，持續擴散討論，提高聲量與品牌認知。</span> </div>
              </div>
              <div className="col-md-4 col-lg-4 col-sm-12 wow bounceIn" data-wow-delay="0.5s">
                  <div className="h-100 bg-dark-resound p-4 p-xl-4 ius">
                    <div className="d-flex align-items-center justify-content-between mb-4 ius">
                        <div className="btn-square rounded-circle" style={{width: '60px', height: '64px'}}> <img className="img-fluid" src="img/icon/icon-3.png" alt="Icon" /> </div>
                    </div>
                    <h5 className="text-warning">達成推廣目標</h5>
                    <hr className="w-25" />
                    <span className="color1">按不同品牌和產品的個別專案目的，客製化專屬行銷策略與宣傳方針，真正達成客戶的需求與專案目標。</span> </div>
              </div>
            </div>
            <div className="text-center py-5"><a href="/#contact" className="btn btn-dark rounded-pill py-3 px-5">洽詢服務</a></div>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
              <div className="bg-primary mb-3 mx-auto" style={{width: '60px', height: '2px'}}></div>
              <h1 className="display-5 mb-5">洽談流程</h1>
              <p className="mb-5">從客戶需求出發，找到核心TA的共鳴，客製化最有感的行銷宣傳創意與方針。</p>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-6 wow fadeInLeft" data-wow-delay="0.1s"> <img className="img-fluid " src="img/bg/Service-1.png" alt="" /> </div>
              <div className="col-md-6 col-lg-6 wow fadeInRight" data-wow-delay="0.1s">
                  <div className="service-item h-100 p-5">
                    <h2 className="mb-3 text1">需求<span>接洽</span></h2>
                    <p>Demand Contact</p>
                    <p className="mb-4">完整接收客戶需求，精準高效溝通，協助釐清專案目的</p>
                  </div>
              </div>
            </div>
            <div className="hr text-center">⇩</div>
            <div className="row">
              <div className="col-md-6 col-lg-6 wow fadeInLeft" data-wow-delay="0.1s">
                  <div className="service-item h-100 p-5">
                    <h2 className="mb-3 text2">創意<span>提案</span></h2>
                    <p className="text3">Creative Proposal</p>
                    <p className="mb-4 text3">按需求客製化，提出符合專案目的以及品牌TA的創意提案</p>
                  </div>
              </div>
              <div className="col-md-6 col-lg-6 wow fadeInRight" data-wow-delay="0.1s"> <img className="img-fluid " src="img/bg/Service-2.png" alt="" /> </div>
              <div className="hr text-center">⇩</div>
              <div className="col-md-6 col-lg-6 wow fadeInLeft" data-wow-delay="0.1s"> <img className="img-fluid " src="img/bg/Service-3.png" alt="" /> </div>
              <div className="col-md-6 col-lg-6 wow fadeInRight" data-wow-delay="0.1s">
                  <div className="service-item h-100 p-5">
                    <h2 className="mb-3 text1">整合<span>溝通</span></h2>
                    <p>Integrated Communication</p>
                    <p className="mb-4">按照提案需求、預算，和客戶討論並優化</p>
                  </div>
              </div>
              <div className="hr text-center">⇩</div>
              <div className="col-md-6 col-lg-6 wow fadeInLeft" data-wow-delay="0.1s">
                  <div className="service-item h-100 p-5">
                    <h2 className="mb-3 text2">確認<span>報價</span></h2>
                    <p className="text3">Confirmation And Quotation</p>
                    <p className="mb-4 text3">雙方確認專案及報價預算及執行內容</p>
                  </div>
              </div>
              <div className="col-md-6 col-lg-6 wow fadeInRight" data-wow-delay="0.1s"> <img className="img-fluid " src="img/bg/Service-4.png" alt="" /> </div>
              <div className="hr text-center">⇩</div>
              <div className="col-md-6 col-lg-6 wow fadeInLeft" data-wow-delay="0.1s"> <img className="img-fluid " src="img/bg/Service-5.png" alt="" /> </div>
              <div className="col-md-6 col-lg-6 wow fadeInRight" data-wow-delay="0.1s">
                  <div className="service-item h-100 p-5">
                    <h2 className="mb-3 text1">完整<span>執行</span></h2>
                    <p>Fully Implemented</p>
                    <p className="mb-4">按照排程需求規劃，完整執行專案內容</p>
                  </div>
              </div>
              <div className="hr text-center">⇩</div>
              <div className="col-md-6 col-lg-6 wow fadeInLeft" data-wow-delay="0.1s">
                  <div className="service-item h-100 p-5">
                    <h2 className="mb-3 text2">結案<span>驗收</span></h2>
                    <p className="text3">Finished Product Acceptance</p>
                    <p className="mb-4 text3">成品結案後，提供完整的成效數據、優化策略</p>
                  </div>
              </div>
              <div className="col-md-6 col-lg-6 wow fadeInRight" data-wow-delay="0.1s"> <img className="img-fluid " src="img/bg/Service-6.png" alt="" /> </div>
            </div>
              <div className="text-center py-5"><a href="/#contact" className="btn btn-dark rounded-pill py-3 px-5">洽詢服務</a></div>
        </div>
      </div>
    </Fragment>
  );
};

export default ServiceIndex;
