import React, { Fragment, useEffect } from "react";

const Video = () => {
  useEffect(() => {
    let message = "突破框架 走在前端 流量製造專家";
    let msgCount = 0;
    let timer1;
    let messageLabel = document.getElementById("messageLabel");

    timer1 = setInterval(() => {
      messageLabel.innerHTML = message.substring(0, msgCount);

      if (msgCount === message.length) {
        clearInterval(timer1);
      } else {
        msgCount++;
      }
    }, 150);
  }, []);
  return (
    <Fragment>
      <div className="container-fluid py-5 short">
        <div className="container">
            <h1 className="effect" id="messageLabel">突破框架 走在前端 流量製造專家</h1>
            <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: '500px'}}>
              <div className="bg-primary mb-3 mx-auto" style={{width: '60px', height: '2px'}}></div>
              <h1 className="display-6">短影音一條龍服務</h1>
            </div>
            <div className="owl-carousel-item roadmap-carousel wow fadeInUp text-center mt-5" data-wow-delay="0.1s">
              <div className="roadmap-item">
                  <div className="roadmap-point"><span>1</span></div>
                  <h5>掌握品牌要素</h5>
                  <span>嶄露品牌獨一無二的個性</span> </div>
              <div className="roadmap-item">
                  <div className="roadmap-point"><span>2</span></div>
                  <h5>訂製行銷創意</h5>
                  <span>向市場傳達品牌的價值與目的</span> </div>
              <div className="roadmap-item">
                  <div className="roadmap-point"><span>3</span></div>
                  <h5>網紅產出短影</h5>
                  <span>幾秒的短音，取代幾百個文字所要傳達的訊息</span> </div>
              <div className="roadmap-item">
                  <div className="roadmap-point"><span>4</span></div>
                  <h5>社群發酵爆款</h5>
                  <span></span> </div>
            </div>
        </div>
      </div>
      <div className="resound">
        <div className="marquee">
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
        </div>
        <div className="marquee">
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
        </div>
      </div>

      <div className="container-fluid py-5 bg-light">
        <div className="container">
            <div className="text-center">
              <div className="bg-primary mb-3 mx-auto" style={{width: '60px', height: '2px'}}></div>
              <h1 className="display-5 mb-5">短影音精選案例</h1>
            </div>
            <div className="row g-0 service-row owl-carousel">
              <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.1s">
                  <div className="p-4">
                    <div className="mb-3" style={{ width: "auto" }}> 
                        <video controls="true" controlslist="nodownload" style={{background:'#FFF', border:'0', borderRadius:'3px', boxShadow:'0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)', margin: '1px', maxWidth:'540px', minWidth:'326px', padding:'0', width:'99.375%', width:'-webkit-calc(100% - 2px)', width:'calc(100% - 2px)'}}>
                          <source src="img/video/112.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <h4>&ensp;巔峰極速ｘ賞金計畫短影音推廣</h4>
                    <p className="mb-3 color2">巔峰極速官方賞金計畫第一期，與32名TIKTOK創作者合作在地風格的創意影片，強力曝光推廣賞金活動，邀請大家一同玩遊戲參加！創意短影片總觀看數達1500萬+。</p>
                      <span className="label">#短影音</span><span className="label">#KOC</span><span className="label">#KOL</span>
                  </div>
              </div>
              <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.3s"> <a href="https://www.tiktok.com/@identityv_tw?is_from_webapp=1&sender_device=pc">
                  <div className="p-4">
                    <div className="mb-3" style={{ width: "auto" }}> 
                        <video controls="true" controlslist="nodownload" style={{background:'#FFF', border:'0', borderRadius:'3px', boxShadow:'0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)', margin: '1px', maxWidth:'540px', minWidth:'326px', padding:'0', width:'99.375%', width:'-webkit-calc(100% - 2px)', width:'calc(100% - 2px)'}}>
                          <source src="img/video/identityv.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <h4>&ensp;第五人格</h4>
                    <p className="mb-3 color2">20支短影音，總累積1,018萬觀看，誕生一波100萬爆款短影音</p>
                  </div>
                  </a> </div>
              <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                  <div className="p-4">
                    <div className="mb-3" style={{ width: "auto" }}> 
                        <video controls="true" controlslist="nodownload" style={{background:'#FFF', border:'0', borderRadius:'3px', boxShadow:'0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)', margin: '1px', maxWidth:'540px', minWidth:'326px', padding:'0', width:'99.375%', width:'-webkit-calc(100% - 2px)', width:'calc(100% - 2px)'}}>
                          <source src="img/video/knivesout.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <h4>&ensp;荒野行動</h4>
                    <p className="mb-3 color2">趣味拍攝荒野行動五週年生日祝賀，觀看達成率167% +</p>
                  </div>
              </div>
            </div>
            <div className="text-center py-5"><a href="/videowork" className="btn btn-dark rounded-pill py-3 px-5">觀看更多</a></div>
        </div>
      </div>

    </Fragment>
  );
};

export default Video;
