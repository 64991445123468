import React, { Fragment, useEffect } from "react";
import CarouselBox from "./CarouselBox";
import Video from "./Video";
import Case from "./Case";
import KolTT from "./KolTT";
import KolList from "./KolList";
import Cooperation from "./Cooperation";
import Contact from "./Contact";

const HomeIndex = () => {
  useEffect(() => {
    let url = window.location.hash;
    let anchor = url.indexOf("#") >= 0 ? url.substring(url.indexOf("#")+1) : "";
    if (anchor)
    {
      let anchorElement = document.getElementById(anchor);
      if (anchorElement) {
        anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'});
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      window.scrollTo(0, 0);
    }
  },[]);
  return (
    <Fragment>      
      <CarouselBox />
      <Video />
      <Case />
      <KolTT page="home" />
      <KolList />
      <Cooperation />
      <Contact />
    </Fragment>
  );
};

export default HomeIndex;
