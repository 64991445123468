import React, { Fragment } from "react";

const WorkIndex = () => {
  return (
    <Fragment>
      <div className="container-fluid page-header py-5 mb-5">
        <div className="container py-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">精選案例</h1>
            <nav aria-label="breadcrumb animated slideInDown">
              <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a className="text-white" href="/">首頁</a></li>
                  <li className="breadcrumb-item text-white active" aria-current="page">精選案例</li>
              </ol>
            </nav>
        </div>
      </div>

      <div className="container-fluid py-5">
        <div className="container"> 

            <div className="row g-0 service-row">
              <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.1s">
                  <div className="p-3">
                    <div className="mb-3" style={{width: 'auto'}}> <img className="img-fluid" src="img/showcase/case21.jpg" alt="" /> </div>
                    <h4>&ensp;巔峰極速×線上娛樂賽</h4>
                    <hr />    
                    <p className="mb-3 color2">階段性操作，從「KOL嗆聲預熱」、「啦啦隊應援拉票」、「線上友誼團練」、「直播決賽」、「懲罰祭品影片」，極大化賽事流量，<span className="draw">總曝光數400萬+，互動數10萬次+</span>。</p>
                    <span className="label">線下活動</span><span className="label">KOL</span></div>
              </div>
              <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.1s">
                  <div className="p-3">
                    <div className="mb-3" style={{width: 'auto'}}> <img className="img-fluid" src="img/showcase/case22.jpg" alt="" /> </div>
                    <h4>&ensp;巔峰極速 預熱期推廣</h4>
                    <hr />    
                    <p className="mb-3 color2">預熱推廣與31位不同領域的KOL合作，包含：車圈名人FB貼文分享官方紀錄片、YouTuber影片中插置入合作，中高流量遊戲實況主遊戲試玩直播，以及賽車相關手遊實況主實況合作。<br />在遊戲未上線前，創造新潮話題、吸引玩家目光，<br /><span className="draw">車圈名人轉發分享官方紀錄片，總曝光數帶來150萬+</span>。<br /><span className="draw">實況主實況與精華影片總觀看數累積80萬</span>。</p>
                    <span className="label">KOL</span><span className="label">YouTuber</span><span class="label">遊戲實況</span><span className="label">車圈名人</span></div>
              </div>
              <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.1s">
                  <div className="p-3">
                    <div className="mb-3" style={{width: 'auto'}}> <img className="img-fluid" src="img/showcase/case23.jpg" alt="" /> </div>
                    <h4>&ensp;巔峰極速 上市期KOL全案推廣</h4>
                    <hr />    
                      <p className="mb-3 color2">遊戲上市一個月強力推廣，與52位不同領域的KOL合作，包含：街頭塗鴉藝術家、圖文作家、藝人、車圈相關與泛娛樂向YouTuber、遊戲實況主，以及TIKTOK創作者。多元跨界的合作方式，例如：街頭塗鴉藝術家、圖文作家合作在遊戲內繪製車體外觀並上架創意工坊；藝人、車圈相關與泛娛樂向YouTuber合作置入創意影片或新遊戲體驗，<span className="draw">影片總觀看數200萬+</span>。<br /><span className="draw">遊戲實況主實況與精華影片總觀看數累積120萬+</span>；<span className="draw">TIKTOK創意影片更達到總觀看數800萬+</span>，營造大家都在玩巔峰極速的氛圍。</p>
                    <span className="label">KOL</span><span className="label">YouTuber</span><span className="label">遊戲實況</span><span className="label">車圈名人</span><span className="label">圖文作家</span><br /><span className="label">藝術家</span><span className="label">跨界合作</span></div>
              </div>
              <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.1s">
                  <div className="p-3">
                    <div className="mb-3" style={{width: 'auto'}}> <img className="img-fluid" src="img/showcase/case24.jpg" alt="" /> </div>
                    <h4>&ensp;巔峰極速 三創巔峰基地站</h4>
                    <hr />    
                      <p className="mb-3 color2">遊戲上市期間在台北三創園區廣場舉辦為期13天的線下活動，現場不僅有試玩機可體驗遊戲、還有圈速挑戰活動，吸引玩家來挑戰刷榜。假日也有PG推廣路人下載遊戲。<br />特別是11/4當日下午有【一日站長競速PK賽】，邀請妲妲跟湘湘與玩家們體驗賽車飆速！晚上還有轉播官方娛樂賽，邀請路人一同觀看比賽、抽獎互動。</p>
                    <span className="label">KOL</span><span className="label">線下活動</span><span className="label">賽事轉播</span></div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6 wow zoomIn" data-wow-delay="0.1s">
                  <div className="p-3">
                    <div className="mb-3" style={{width: 'auto'}}> <img className="img-fluid" src="img/showcase/case01.jpg" alt="" /></div>
                    <h4>&ensp;荒野行動×這群人</h4>
                    <hr />
                    <p className="mb-3 color2">年度行銷主軸，身邊好友都在玩！<br />
                        每月份依據主軸規劃與執行產出具高度網路影響力的影片，聯動<span className="draw">共9支</span>，運用投放組合，<span className="draw">創造「破億」觀看數</span>。 </p>
                    <span className="label">YouTuber</span>  
                  </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6 wow zoomIn" data-wow-delay="0.3s">
                  <div className="p-3">
                    <div className="mb-3" style={{width: 'auto'}}> <img className="img-fluid" src="img/showcase/case02.jpg" alt="" /> </div>
                    <h4>&ensp;明日之後×葉式特工</h4>
                    <hr />
                    <p className="mb-3 color2"><span className="draw">掌握產品主軸適配網紅</span> ，打造「我的活屍女友」網路劇IP，喪屍搭配特效製作，打中目標受眾，具高度網路影響力影片，<span className="draw">創造破5,000萬觀看數</span>。</p>
                    <span className="label">YouTuber</span><span className="label">KOL</span></div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6 wow zoomIn" data-wow-delay="0.5s">
                  <div className="p-3">
                    <div className="mb-3" style={{width: 'auto'}}> <img className="img-fluid" src="img/showcase/case03.jpg" alt="" /> </div>
                    <h4>&ensp;第五人格×Nike</h4>
                    <hr />
                    <p className="mb-3 color2"><span className="draw">驚奇新鮮的虛實整合，聯動NIKE</span>推出遊戲時裝、道具，搭配網紅、聯播網、VOGUE & GQ雜誌、實體店面、夜跑活動等媒體宣傳，活動期間<span className="draw">曝光超過8,000+萬次</span>。<br />
                        此專案<span className="draw">榮獲DMA</span>（Digital Media Award），<span className="draw">年度銀獎</span>。</p>
                    <span className="label">異業合作</span><span className="label">線下活動</span><span className="label">媒體推廣</span></div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6 wow zoomIn" data-wow-delay="1.1s">
                  <div className="p-3">
                    <div className="mb-3" style={{width: 'auto'}}> <img className="img-fluid" src="img/showcase/case04.jpg" alt="" /> </div>
                    <h4>&ensp;盜墓筆記×藝人×實況主</h4>
                    <hr />
                    <p className="mb-3 color2">從<span className="draw">代言人適配</span>到多位實況主接力宣傳，搭配<span className="draw">趣味的雙人實況操作</span>，達到遊戲上市階段性推廣效益，實況主單人最高<span className="draw">同上突破5,000人</span>，最高<span className="draw">精華片超過20萬觀看數</span>。</p>
                    <span className="label">代言人</span><span className="label">實況節目</span> </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6 wow zoomIn" data-wow-delay="1.3s">
                  <div className="p-3">
                    <div className="mb-3" style={{width: 'auto'}}> <img className="img-fluid" src="img/showcase/case05.jpg" alt="" /> </div>
                    <h4>&ensp;明日之後×短影音×地推</h4>
                    <hr />
                    <p className="mb-3 color2">遊戲改版，線下<span className="draw">超寫實遊戲體驗快閃店</span>，適配多位KOLs到現場體驗活動，拍攝<span className="draw">符合遊戲內容的創意短影音</span>，達到趣味又自然的推廣效果，<span className="draw">總觀看次數超過480+萬次</span>。</p>
                    <span className="label">短影音</span><span className="label">線下活動</span> </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6 wow zoomIn" data-wow-delay="1.5s">
                  <div className="p-3">
                    <div className="mb-3" style={{width: 'auto'}}> <img className="img-fluid" src="img/showcase/case06.jpg" alt="" /> </div>
                    <h4>&ensp;RO仙境傳說×玩家見面會</h4>
                    <hr />
                    <p className="mb-3 color2">包含<span className="draw">全台灣共8場玩家見面和同樂會，與1場香港網聚活動</span>，搭配KOL和藝人來賓黃偉晉、赤鬼伯伯，讓玩家在快樂氛圍中，交流、同樂、抽獎、對戰，<span className="draw">拉近玩家與官方的距離</span>。</p>
                    <span className="label">線下活動</span> </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6 wow zoomIn" data-wow-delay="2.1s">
                  <div className="p-3">
                    <div className="mb-3" style={{width: 'auto'}}> <img className="img-fluid" src="img/showcase/case07.jpg" alt="" /> </div>
                    <h4>&ensp;第五人格TikTok頻道</h4>
                    <hr />
                    <p className="mb-3 color2">以創意內容的自然流量經營，由0開始，至目前已有184K訂閱，每則短影音平均觀看數5,000+，每月月觀看數，穩定30w~40w觀看流量，頻道 總累積觀看數3,800萬+</p>
                    <span className="label">社群經營</span><span className="label">Coser</span> </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6 wow zoomIn" data-wow-delay="2.3s">
                  <div className="p-3">
                    <div className="mb-3" style={{width: 'auto'}}> <img className="img-fluid" src="img/showcase/case08.jpg" alt="" /> </div>
                    <h4>&ensp;AR×第五人格</h4>
                    <hr />
                    <p className="mb-3 color2">協助遊戲進行web AR 技術與宣傳包裝，提供玩家使用手機即可體驗。</p>
                    <span className="label">AR</span> </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6 wow zoomIn" data-wow-delay="2.5s">
                  <div className="p-3">
                    <div className="mb-3" style={{width: 'auto'}}> <img className="img-fluid" src="img/showcase/case09.jpg" alt="" /> </div>
                    <h4>&ensp;義大遊樂世界×第五人格</h4>
                    <hr />
                    <p className="mb-3 color2">臺灣高雄最大遊樂園-義大遊樂世界置入《第五人格》遊戲裝置讓玩家們朝聖，規劃現場活動、Coser推廣、媒體宣傳，活動累積曝光 5,000,000+。</p>
                    <span className="label">線下活動</span> </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6 wow zoomIn" data-wow-delay="2.1s">
                  <div className="p-3">
                    <div className="mb-3" style={{width: 'auto'}}> <img className="img-fluid" src="img/showcase/case10.jpg" alt="" /> </div>
                    <h4>&ensp;明日之後×PORTER</h4>
                    <hr />
                    <p className="mb-3 color2">共同參加Vogue FNO 2019，于信義威秀廣場舉辦末日快閃店，遊戲內推出聯動合作PORTER包款，臺灣高人氣網紅情侶檔-眾量級推廣、媒體宣傳、活動花絮影片，活動累積總曝光 15,000,000+。</p>
                    <span className="label">異業合作</span> </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6 wow zoomIn" data-wow-delay="2.3s">
                  <div className="p-3">
                    <div className="mb-3" style={{width: 'auto'}}> <img className="img-fluid" src="img/showcase/case11.jpg" alt="" /> </div>
                    <h4>&ensp;明日之後×Foodpanda</h4>
                    <hr />
                    <p className="mb-3 color2">遊戲內置入foodpanda外送員npc、外送車及外送箱、精選食品頭框置入、海報宣傳等，搭配贈送優惠碼、網紅推廣、<a href="https://youtu.be/Sa6V_XaKvp0" target="_blank">影音影片A</a> / <a href="https://youtu.be/2GLwdJz4P0M" target="_blank">影音影片B</a> / <a href="https://youtu.be/gxn1VJoqWjQ" target="_blank">影音影片C</a>、媒體宣傳，活動累積總曝光 12,000,000+。</p>
                    <span className="label">異業合作</span> </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6 wow zoomIn" data-wow-delay="2.5s">
                  <div className="p-3">
                    <div className="mb-3" style={{width: 'auto'}}> <img className="img-fluid" src="img/showcase/case12.jpg" alt="" /> </div>
                    <h4>&ensp;決戰！平安京×SONY</h4>
                    <hr />
                    <p className="mb-3 color2">與 SONY 最新款XPERIA手機共同推廣，遊戲及推廣資源互惠合作，宣傳影片露出於TVC、電影院、全台百貨公司線下影音等媒體活動期間累積曝光數 50,000,000+。</p>
                    <span className="label">異業合作</span> </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6 wow zoomIn" data-wow-delay="2.1s">
                  <div className="p-3">
                    <div className="mb-3" style={{width: 'auto'}}> <img className="img-fluid" src="img/showcase/case13.jpg" alt="" /> </div>
                    <h4>&ensp;明日之後×補給隊出發</h4>
                    <hr />
                    <p className="mb-3 color2">明日之後於周年慶，推出補給車出發！創意概念，打造補給車於台北進行補給行動，包含送專屬物資箱給長期合作KOL、VIP玩家，以及前進流浪動物之家的愛心補給，最後來到台北ATT與三創為玩家舉辦全天候的現場補給活動！</p>
                    <span className="label">公益活動</span><span className="label">線下活動</span></div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6 wow zoomIn" data-wow-delay="2.3s">
                  <div className="p-3">
                    <div className="mb-3" style={{width: 'auto'}}> <img className="img-fluid" src="img/showcase/case14.jpg" alt="" /> </div>
                    <h4>&ensp;國泰金控×NBA</h4>
                    <hr />
                    <p className="mb-3 color2">神預測達人活動！邀請具有影響力的運動類KOL於活動首日展開預測與貼文，並運用國泰NBA專屬濾鏡吸引社群粉絲參與</p>
                    <span className="label">口碑操作</span> </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6 wow zoomIn" data-wow-delay="2.5s">
                  <div className="p-3">
                    <div className="mb-3" style={{width: 'auto'}}> <img className="img-fluid" src="img/showcase/case15.jpg" alt="" /> </div>
                    <h4>&ensp;張庭瑚×德里斯巧克力</h4>
                    <hr />
                    <p className="mb-3 color2">粉絲團經營及貼文廣告操作，從文案設計到視覺為客戶提供精闢建議，維持產品社群關注度，結合精準廣告投放，使宣傳放益最大化。</p>
                    <span className="label">社群經營</span> </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6 wow zoomIn" data-wow-delay="2.1s">
                  <div className="p-3">
                    <div className="mb-3" style={{width: 'auto'}}> <img className="img-fluid" src="img/showcase/case16.jpg" alt="" /> </div>
                    <h4>&ensp;模型少女AWAKE</h4>
                    <hr />
                    <p className="mb-3 color2">粉絲團經營及巴哈姆特討論區操作，從文案設計到社群二次創作、遊戲攻略，為客戶提供精闢建議，維持產品社群粘著度，建立忠誠客戶，持續關注遊戲話題。</p>
                    <span className="label">社群經營</span><span className="label">口碑操作</span> </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6 wow zoomIn" data-wow-delay="2.3s">
                  <div className="p-3">
                    <div className="mb-3" style={{width: 'auto'}}> <img className="img-fluid" src="img/showcase/case17.jpg" alt="" /> </div>
                    <h4>&ensp;Re:從零開始的異世界生活</h4>
                    <hr />
                    <p className="mb-3 color2">除常規知名日系IP的粉絲團發文操作，另外經營其IP人氣角色粉絲團，模擬其角色性格及口吻，搭配時下話題、梗圖於社群平台與大眾有趣互動，維持產品社群關注度，持續活躍IP支持度。</p>
                    <span className="label">社群經營</span><span className="label">口碑操作</span><span className="label">KOL</span> </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6 wow zoomIn" data-wow-delay="2.5s">
                  <div className="p-3">
                    <div className="mb-3" style={{width: 'auto'}}> <img className="img-fluid" src="img/showcase/case18.jpg" alt="" /> </div>
                    <h4>&ensp;《遊戲完蛋了》線上推廣</h4>
                    <hr />
                    <p className="mb-3 color2">由韓國知名遊戲廠開發和台灣橘子集團旗下的GASH聯合營運的手遊，並納入區塊鏈遊戲的機制，打造P2E的全新鏈遊體驗</p>
                    <span className="label">區塊鏈遊戲</span> </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6 wow zoomIn" data-wow-delay="2.1s">
                  <div className="p-3">
                    <div className="mb-3" style={{width: 'auto'}}> <img className="img-fluid" src="img/showcase/case19.jpg" alt="" /> </div>
                    <h4>&ensp;《太空鱷魚：重生》社群操作</h4>
                    <hr />
                    <p className="mb-3 color2">由台灣本土知名NFT項目《太空鱷魚》延伸的GameFi鏈遊《太空鱷魚：重生》遊戲開發商搭配專屬儲值優惠打造全新台灣本土鏈遊NFT及超強賦能藉由呼聲對於Web3領域的關注與熟悉用適當的口吻及時事議題打造遊戲項目方和TA之間的橋樑並適當傳遞官方欲曝光的資訊經營包含：FB、IG、Twitter、 Discord</p>
                    <span className="label">區塊鏈遊戲</span> </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6 wow zoomIn" data-wow-delay="2.3s">
                  <div className="p-3">
                    <div className="mb-3" style={{width: 'auto'}}> <img className="img-fluid" src="img/showcase/case20.jpg" alt="" /> </div>
                    <h4>&ensp;格蘭菲迪威士忌新品發表會</h4>
                    <hr />
                    <p className="mb-3 color2">新品發表會籌備&執行/場地佈置營造/舞團表演/藝人出席/活動流程控管</p>
                    <span className="label">線下活動</span> <span className="label">藝人</span></div>
              </div>
            </div>
        </div>
      </div>
    </Fragment>
  );
};

export default WorkIndex;
