import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import classnames from "classnames";
import axios from "axios";
import "./contact.css";

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const { register, handleSubmit, errors } = useForm(); // initialise the hook

  const onSubmit = (contactMessage) => {
    //console.log(contactMessage);
    setLoading(true);
    setResult(null);

    axios
      .post("/api/contactusv2/", {
        ...contactMessage,
      })
      .then((res) => {

        setLoading(false);
        setResult("success");
      })
      .catch((err) => {
        setLoading(false);
        setResult("error");
        //console.log(err.msg);
      });
  };

  return (
    <Fragment>
      <div className="container-fluid overflow-hidden my-5 px-lg-0 bg-light" id="contact">
        <div className="container quote px-lg-0">
            <div className="row g-0 mx-lg-0">
              <div className="col-lg-5 ps-lg-0" style={{minHeight: '400px'}}>
                  <div className="position-relative h-100"> <img className="position-absolute img-fluid w-100 h-100" src="img/quote.jpg" style={{objectFit: 'cover'}} alt="" /> </div>
              </div>
              <div className="col-lg-7 quote-text py-5 wow fadeIn" data-wow-delay="0.5s">
                  <div className="p-lg-5 pe-lg-0">
                    <div className="bg-primary mb-3" style={{width: '60px', height: '2px'}}></div>
                    <h1 className="display-5 mb-5">聯絡我們</h1>
                    <p className="mb-4 color2">若您有任何廣告、整合行銷、媒體採購、行銷活動等需求，歡迎於下方留下您的聯繫方式，Resound 呼聲將有專人為您服務。</p>
                    <form
                      id="contactForm"
                      noValidate="noValidate"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                        <div className="row g-3">
                          <div className="col-12 col-sm-6">
                              <input type="text" id="name" name="name" 
                                placeholder={errors.name?.message || "姓名"}
                                ref={register({
                                  required: "*必填，請告訴我們該怎麼稱呼您",
                                  maxLength: 20,
                                })}
                                className={classnames("form-control border-0", {
                                  "not-valid": errors.name,
                                })}
                                style={{height: '55px'}} />
                          </div>
                          <div className="col-12 col-sm-6">
                              <input type="email" id="email" name="email" 
                                className={classnames("form-control border-0", {
                                  "not-valid": errors.email,
                                })}
                                placeholder={errors.email?.message || "信箱"}
                                ref={register({
                                  required: "*必填，請輸入您的電子郵件",
                                  pattern: {
                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                    message: "電子郵件格式不符合",
                                  },
                                })}
                                style={{height: '55px'}} />
                          </div>
                          <div className="col-12 col-sm-6">
                              <input type="text" id="phone" name="phone"
                                placeholder={errors.phone?.message || "聯絡電話"}
                                ref={register({
                                  required: "*必填，請提供手機或電話",
                                  maxLength: 30,
                                })}
                                className={classnames("form-control border-0", {
                                  "not-valid": errors.phone,
                                })}
                                style={{height: '55px'}} />
                          </div>
                          <div className="col-12 col-sm-6">
                              <select id="messageCategory" name="messageCategory" 
                                ref={register({
                                  required: "*必填",
                                })}
                                className={classnames("form-select border-0", {
                                  "not-valid": errors.messageCategory,
                                })}
                                style={{height: '55px'}}>
                                <option value="">我想了解</option>
                                <option value="KOL行銷">KOL行銷</option>
                                <option value="短影音">短影音</option>
                                <option value="口碑行銷">口碑行銷</option>
                                <option value="其他">其他</option>
                              </select>
                          </div>
                          <div className="col-12 col-sm-6">
                              <input type="text" id="company" name="company"
                                placeholder={errors.company?.message || "公司名稱"}
                                ref={register({
                                  required: "*必填，請輸入公司名稱",
                                  maxLength: 30,
                                })}
                                className={classnames("form-control border-0", {
                                  "not-valid": errors.company,
                                })}
                                style={{height: '55px'}} />
                          </div>
                          <div className="col-12 col-sm-6">
                              <select id="companyCategory" name="companyCategory" 
                                ref={register({
                                  required: "*必填",
                                })}
                                className={classnames("form-select border-0", {
                                  "not-valid": errors.companyCategory,
                                })}
                                style={{height: '55px'}}>
                                <option value="">公司產業類別</option>
                                <option value="遊戲">遊戲</option>
                                <option value="美妝保養品">美妝保養品</option>
                                <option value="旅遊/餐飲">旅遊/餐飲</option>
                                <option value="3C/科技">3C/科技</option>
                                <option value="運動健身">運動健身</option>
                                <option value="其他">其他</option>
                              </select>
                          </div>
                          <div className="col-12">
                              <textarea name="message" id="message"
                              placeholder={errors.message?.message || "我們如何協助您"}
                              ref={register({
                                required: "*必填，您需要什麼樣的服務或是有任何建議呢？",
                              })}
                              className={classnames("form-control border-0", {
                                "not-valid border-0": errors.message,
                              })}
                              ></textarea>
                          </div>
                          {loading ? (
                          <div id="spinner" className="show text-center py-3">
                            <div className="spinner-border text-primary" style={{width: '3rem', height: '3rem'}} role="status"> <span className="sr-only">Loading...</span> </div>
                          </div>
                          ) : (
                          <div className="text-center py-3"><button type="submit" className="btn btn-dark rounded-pill py-3 px-5">送出</button></div>
                          )}
                          {result === "success" && (
                            <alert className="alert alert-success">感謝來信，在近期將有專人與您聯絡。</alert>
                          )}
                          {result === "error" && (
                            <alert className = "alert alert-danger">請稍候再試，謝謝!!</alert>
                          )}
                        </div>
                    </form>
                    
                  </div>
              </div>
            </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Contact;
