import React, { Fragment } from "react";

const Case = () => {
  return (
    <Fragment>
      <div className="container-fluid py-5 case">
        <div className="container">
            <div className="text-center">
              <div className="bg-primary mb-3 mx-auto" style={{width: '60px', height: '2px'}}></div>
              <h1 className="display-5 mb-5">最新案例</h1>
            </div>
            <div className="row g-0 service-row owl-carousel">
              <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.1s">
                  <div className="p-4">
                    <div className="mb-3" style={{ width: "auto" }}> <img className="img-fluid" src="img/showcase/case22.jpg" alt="" /> </div>
                    <h4>&ensp;巔峰極速 預熱期推廣</h4>
                    <p className="mb-3 color2">預熱推廣與31位不同領域的KOL合作，包含：車圈名人FB貼文分享官方紀錄片、YouTuber影片中插置入合作，中高流量遊戲實況主遊戲試玩直播，以及賽車相關手遊實況主實況合作。<br />在遊戲未上線前，創造新潮話題、吸引玩家目光，<br /><span className="draw">車圈名人轉發分享官方紀錄片，總曝光數帶來150萬+</span>。<br /><span className="draw">實況主實況與精華影片總觀看數累積80萬</span>。</p>
                    <span className="label">KOL</span><span className="label">YouTuber</span><span className="label">遊戲實況</span><span className="label">車圈名人</span></div>
              </div>
              <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                  <div className="p-4">
                    <div className="mb-3" style={{ width: "auto" }}> <img className="img-fluid" src="img/showcase/case23.jpg" alt="" /> </div>
                    <h4>&ensp;巔峰極速 上市期KOL全案推廣</h4>
                    <p className="mb-3 color2">遊戲上市一個月強力推廣，與52位不同領域的KOL合作，包含：街頭塗鴉藝術家、圖文作家、藝人、車圈相關與泛娛樂向YouTuber、遊戲實況主，以及TIKTOK創作者。<br />多元跨界的合作方式，例如：街頭塗鴉藝術家、圖文作家合作在遊戲內繪製車體外觀並上架創意工坊；藝人、車圈相關與泛娛樂向YouTuber合作置入創意影片或新遊戲體驗，<span className="draw">影片總觀看數200萬+</span>。<br /><span className="draw">遊戲實況主實況與精華影片總觀看數累積120萬+</span>；<span className="draw">TIKTOK創意影片更達到總觀看數800萬+</span>，營造大家都在玩巔峰極速的氛圍。</p>
                    <span className="label">KOL</span><span className="label">YouTuber</span><span className="label">遊戲實況</span><span className="label">車圈名人</span><span className="label">圖文作家</span><span className="label">藝術家</span><span className="label">跨界合作</span></div>
              </div>
              <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                  <div className="p-4">
                    <div className="mb-3" style={{ width: "auto" }}> <img className="img-fluid" src="img/showcase/case24.jpg" alt="" /> </div>
                    <h4>&ensp;巔峰極速 三創巔峰基地站</h4>
                    <p className="mb-3 color2">遊戲上市期間在台北三創園區廣場舉辦為期13天的線下活動，現場不僅有試玩機可體驗遊戲、還有圈速挑戰活動，吸引玩家來挑戰刷榜。假日也有PG推廣路人下載遊戲。<br />特別是11/4當日下午有【一日站長競速PK賽】，邀請妲妲跟湘湘與玩家們體驗賽車飆速！晚上還有轉播官方娛樂賽，邀請路人一同觀看比賽、抽獎互動。</p>
                    <span className="label">KOL</span><span className="label">線下活動</span><span className="label">賽事轉播</span></div>
              </div>
            </div>
        </div>
      </div>
      <div className="container-fluid py-5 case">
        <div className="container">
            <div className="text-center">
              <div className="bg-primary mb-3 mx-auto" style={{width: '60px', height: '2px'}}></div>
              <h1 className="display-5 mb-5">經典案例</h1>
            </div>
            <div className="row g-0 service-row owl-carousel">
              <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.1s">
                  <div className="p-4">
                    <div className="mb-3" style={{ width: "auto" }}> <img className="img-fluid" src="img/showcase/case21.jpg" alt="" /> </div>
                    <h4>&ensp;巔峰極速×線上娛樂賽</h4>
                    <p className="mb-3 color2">階段性操作，從「KOL嗆聲預熱」、「啦啦隊應援拉票」、「線上友誼團練」、「直播決賽」、「懲罰祭品影片」，極大化賽事流量，<span className="draw">總曝光數400萬+，互動數10萬次+</span>。</p>
                    <span className="label">線下活動</span><span className="label">KOL</span></div>
              </div>
              <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                  <div className="p-4">
                    <div className="mb-3" style={{ width: "auto" }}> <img className="img-fluid" src="img/showcase/case01.jpg" alt="" /> </div>
                    <h4>&ensp;荒野行動×這群人</h4>
                    <p className="mb-3 color2">年度行銷主軸，身邊好友都在玩！每月份依據主軸規劃與執行產出具高度網路影響力的影片，年動<span className="draw">共9支</span>，運用投放組合，<span className="draw">創造「破億」觀看數</span>。</p>
                    <span className="label">YouTuber</span></div>
              </div>
              <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                  <div className="p-4">
                    <div className="mb-3" style={{ width: "auto" }}> <img className="img-fluid" src="img/showcase/case02.jpg" alt="" /> </div>
                    <h4>&ensp;明日之後×葉式特工</h4>
                    <p className="mb-3 color2"><span className="draw">掌握產品主軸適配網紅</span> ，打造「我的活屍女友」網路劇IP，喪屍搭配特效製作，打中目標受眾，具高度網路影響力影片，<span className="draw">創造破5,000萬觀看數</span>。</p>
                    <span className="label">YouTuber</span></div>
              </div>
              <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.7s">
                  <div className="p-4">
                    <div className="mb-3" style={{ width: "auto" }}> <img className="img-fluid" src="img/showcase/case03.jpg" alt="" /> </div>
                    <h4>&ensp;第五人格×Nike</h4>
                    <p className="mb-3 color2"><span className="draw">驚奇新鮮的虛實整合，聯動NIKE</span>推出遊戲時裝、道具，搭配網紅、聯播網、VOGUE & GQ雜誌、實體店面、夜跑活動等媒體宣傳，活動期間<span className="draw">曝光超過8,000+萬次</span>。此專案<span className="draw">榮獲DMA</span>（Digital Media Award），<span className="draw">年度銀獎</span>。</p>
                    <span className="label">異業合作</span><span className="label">線下活動</span><span className="label">媒體推廣</span></div>
              </div>
              <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.9s">
                  <div className="p-4">
                    <div className="mb-3" style={{ width: "auto" }}> <img className="img-fluid" src="img/showcase/case04.jpg" alt="" /> </div>
                    <h4>&ensp;盜墓筆記×藝人×實況主</h4>
                    <p className="mb-3 color2">從<span className="draw">代言人適配</span>到溝通到多位實況主接力宣傳，搭配<span className="draw">趣味的雙人實況操作</span>，達到遊戲上市階段性推廣效益，實況主單人最高<span className="draw">同上突破5,000人</span>，最高<span className="draw">精華片超過20萬觀看數</span>。</p>
                    <span className="label">代言人</span><span className="label">實況節目</span> </div>
              </div>
              <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="1.1s">
                  <div className="p-4">
                    <div className="mb-3" style={{ width: "auto" }}> <img className="img-fluid" src="img/showcase/case05.jpg" alt="" /> </div>
                    <h4>&ensp;明日之後×短影音×地推</h4>
                    <p className="mb-3 color2">遊戲改版，除了打造線下<span className="draw">超寫實遊戲體驗快閃店</span>，並適配多位KOLs到現場體驗活動，拍攝<span className="draw">符合遊戲內容的創意短影音</span>，達到趣味又自然的推廣效果，<span className="draw">總觀看次數超過480+萬次</span>。</p>
                    <span className="label">短影音</span><span className="label">線下活動</span> </div>
              </div>
            </div>
            <div className="text-center py-5"><a href="/work" className="btn btn-dark rounded-pill py-3 px-5">觀看更多</a></div>
        </div>
      </div>
    </Fragment>
  );
};

export default Case;
