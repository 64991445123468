import React from "react";

const KolList = () => {
  const artist = [
    { title: "五堅情", image: "img/star-1.jpg" },
    { title: "陳零九&邱鋒澤", image: "img/star-2.jpg" },
    { title: "孫沁岳", image: "img/star-6.jpg" },
    { title: "李芷婷", image: "img/star-7.jpg" },
  ];

  const YTuber = [
    { title: "這群人", image: "img/yt-1.jpg" },
    { title: "眾量級", image: "img/yt-2.jpg" },
    { title: "葉式特工", image: "img/yt-3.jpg" },
    { title: "上班不要看", image: "img/yt-4.jpg" },
    { title: "奎丁", image: "img/yt-5.jpg" },
    { title: "小熊", image: "img/yt-6.jpg" },
    { title: "我不喝拿鐵", image: "img/yt-7.jpg" },
    { title: "老王說", image: "img/yt-8.jpg" },
  ];

  const live = [
    { title: "赤鬼", image: "img/live-1.jpg" },
    { title: "平民百姓", image: "img/live-2.jpg" },
    { title: "殺梗", image: "img/live-3.jpg" },
    { title: "月希", image: "img/live-4.jpg" },
    { title: "龜狗", image: "img/live-5.jpg" },
    { title: "劉萱", image: "img/live-6.jpg" },
    { title: "國動", image: "img/live-7.jpg" },
    { title: "狂暴小建", image: "img/live-8.jpg" },
    { title: "依渟", image: "img/live-9.jpg" },
    { title: "放火", image: "img/live-10.jpg" },
    { title: "咪咪蛋", image: "img/live-11.jpg" },
  ];

  const coser = [
    { title: "狂間", image: "img/cos-1.jpg" },
    { title: "Chihiro千尋", image: "img/cos-2.jpg" },
    { title: "Nori何苔苔", image: "img/cos-3.jpg" },
    { title: "甜食超人-毛毛(Momo)", image: "img/cos-4.jpg" },
    { title: "Chubby糖", image: "img/cos-5.jpg" },
    { title: "HaneAme雨波", image: "img/cos-6.jpg" },
  ];

  const Tiktoker = [
    { title: "李川", image: "img/tk-1.jpg" },
    { title: "木星人", image: "img/tk-2.jpg" },
    { title: "欸!雨群", image: "img/tk-3.jpg" },
    { title: "瑋哥", image: "img/tk-4.jpg" },
    { title: "星宣", image: "img/tk-5.jpg" },
  ];

  const painter = [
    { title: "聞氫哥", image: "img/painter-1.jpg" },
    { title: "霸軒與小美", image: "img/painter-2.jpg" },
  ];

  const VTuber = [
    { title: "杏仁ミル", image: "img/vt-1.jpg" },
    { title: "璐洛洛", image: "img/vt-3.jpg" },
    { title: "KSP", image: "img/vt-4.jpg" },
  ];

  return (
    <div className="container-fluid py-5 bg-light" id="kol">
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <div className="bg-primary mb-3 mx-auto" style={{width: '60px', height: '2px'}}></div>
          <h1 className="display-5 mb-5">網紅合作</h1>
          <p className="mb-5">網羅台灣各平台優質藝人、網紅資源，領域廣、媒合精準快速，成為品牌推廣的最強助力。</p>
        </div>
        <div className="row g-4"> 
          <KolRow kols={artist} />
          <KolRow kols={YTuber} />
          <KolRow kols={live} />
          <KolRow kols={coser} />
          <KolRow kols={Tiktoker} />
          <KolRow kols={painter} />
          <KolRow kols={VTuber} />
        </div>
      </div>
    </div>
  );
};

export default KolList;

const KolRow = ({ kols }) => {
  return (
    kols.map((kol, i) => (
      <div className="col-lg-2 col-md-4 col-sm-4 portfolio-item wow fadeInUp" data-wow-delay={`0.${Math.floor(Math.random()*8)+1}s`}>
        <div className="portfolio-inner"> <img className="w-100" src={kol.image} alt="" />
            <div className="portfolio-text text-center bg-dark mb-6">
              <p className="text-white mb-2">{kol.title}</p>
            </div>
        </div>
      </div>
    ))
  );
};
