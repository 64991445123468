import React, { Fragment, useEffect } from "react";
import KolTT from "../Home/KolTT";

const VideoWorkIndex = () => {
  useEffect(() => {
    let message = "突破框架 走在前端";
    let msgCount = 0;
    let timer1;
    let messageLabel = document.getElementById("messageLabel");

    timer1 = setInterval(() => {
      messageLabel.innerHTML = message.substring(0, msgCount);

      if (msgCount === message.length) {
        clearInterval(timer1);
      } else {
        msgCount++;
      }
    }, 150);
  }, []);
  return (
    <Fragment>      
      <div className="container-fluid page-header py-5 mb-5">
        <div className="container py-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">短影音行銷</h1>
            <nav aria-label="breadcrumb animated slideInDown">
              <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a className="text-white" href="/">首頁</a></li>
                  <li className="breadcrumb-item text-white active" aria-current="page">短影音行銷</li>
              </ol>
            </nav>
        </div>
      </div>

      <div className="container-fluid py-5 text-center short">
        <h2 className="display-1 text-success" id="messageLabel">突破框架 走在前端</h2>
        <h2 className="display-1 text-success wow bounceInUp" data-wow-delay="0.5s">流量製造專家</h2><br />
        <h3 className="mb-5">由2021年急速起步的短影音需求<br />
            至今團隊已累積多款操作案例</h3>
      </div>
          
      <div className="container-xxl py-5">
        <div className="container">
            <div className="text-center wow fadeInUp" data-wow-delay="1.3s">
              <div className="bg-primary mb-3 mx-auto" style={{width: '60px', height: '2px'}}></div>
              <h1 className="display-5 mb-5">掌握品牌／產品需求</h1>
            </div>
            <div className="row g-4 text-center">
              <div className="col-lg-3 col-md-6 col-sm-6 wow bounceIn" data-wow-delay="1.5s">
                  <div className="video-item">
                    <div className="text-center p-4">
                        <h5 className="mb-0"><span className="color4">1</span><br />
                          提取重點要素<br />
                          精煉行銷主軸</h5>
                    </div>
                  </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 wow bounceIn" data-wow-delay="1.7s">
                  <div className="video-item">
                    <div className="text-center p-4">
                        <h5 className="mb-0"><span className="color4">2</span><br />
                          延伸主軸</h5>
                    </div>
                  </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 wow bounceIn" data-wow-delay="1.9s">
                  <div className="video-item">
                    <div className="text-center p-4">
                        <h5 className="mb-0"><span className="color4">3</span><br />
                          官方帳號操作<br />
                          社群活動包裝<br />
                          推薦KOL產出內容</h5>
                    </div>
                  </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 wow bounceIn" data-wow-delay="2.1s">
                  <div className="video-item">
                    <div className="text-center p-4">
                        <h5 className="mb-0"><span className="color4">4</span><br />
                          搭配廣告<br />
                          組合擴散</h5>
                    </div>
                  </div>
              </div>
            </div>
        </div>
      </div>

      <div className="resound">
        <div className="marquee">
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
            <div className="above">Resound</div>
        </div>
        <div className="marquee">
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
            <div className="under">Resound</div>
        </div>
      </div> 

      <div className="container-fluid overflow-hidden py-5 px-lg-0 bg-light text-center">
        <div className="container">
            <div className="text-center">
                  <div className="bg-primary mb-3 mx-auto" style={{width: '60px', height: '2px'}}></div>
                  <h1 className="display-5 mb-5">我們的優勢</h1>
            </div>
            <div className="row g-0 mx-lg-0">
              <div className="col-lg-6 py-5 bg1 wow fadeIn" data-wow-delay="0.5s">
                    <h1 className="display-5 mb-5">官方頻道操作</h1>
                    <p className="mb-4 color2">搭配宣傳節奏 發想創意包裝 腳本客製 內容製作<br />包月形式產出系列遊戲相關內容維持頻道活躍、玩家討論度</p>
                    <img src="img/video-1.png" style={{width: '70%'}} alt="" />
              </div>
              <div className="col-lg-6 py-5 bg2 wow fadeIn" data-wow-delay="0.5s">
                    <h1 className="display-5 mb-5">創作者頻道內容經營</h1>
                    <p className="mb-4 color2">協助創作者擷取精華內容 後製剪輯 維持每月穩定流量<br />優化頻道內容 依互動反應即時調整</p>
                    <img src="img/video-2.png" style={{width: '70%'}} alt="" />
              </div>
            </div>
        </div>
      </div>
          
      <KolTT page="videowork" />

      <div className="container-fluid py-5 bg-light">
        <div className="container"> 
            <div className="text-center">
                  <div className="bg-primary mb-3 mx-auto" style={{width: '60px', height: '2px'}}></div>
                  <h1 className="display-5 mb-5">短影音精彩案例</h1>
                </div>
            <div className="row g-0 service-row owl-carousel">
              <div className="col-md-6 col-lg-4 wow zoomIn" data-wow-delay="0.1s">
                  <div className="p-4">
                    <div className="mb-3" style={{width: 'auto'}}> 
                        <video controls="true" controlslist="nodownload" style={{background:'#FFF', border:'0', borderRadius:'3px', boxShadow:'0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)', margin: '1px', maxWidth:'540px', minWidth:'326px', padding:'0', width:'99.375%', width:'-webkit-calc(100% - 2px)', width:'calc(100% - 2px)'}}>
                          <source src="img/video/112.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <h4>&ensp;巔峰極速ｘ賞金計畫短影音推廣</h4>
                    <p className="mb-3 color2">巔峰極速官方賞金計畫第一期，與32名TIKTOK創作者合作在地風格的創意影片，強力曝光推廣賞金活動，邀請大家一同玩遊戲參加！創意短影片總觀看數達1500萬+。</p>
                      <span className="label">#短影音</span><span className="label">#KOC</span><span className="label">#KOL</span>
                  </div>
                  </div>
              <div className="col-md-6 col-lg-4 wow zoomIn" data-wow-delay="0.1s"> <a href="https://www.tiktok.com/@identityv_tw?is_from_webapp=1&sender_device=pc">
                  <div className="p-4">
                    <div className="mb-3" style={{width: 'auto'}}> 
                        <video controls="true" controlslist="nodownload" style={{background:'#FFF', border:'0', borderRadius:'3px', boxShadow:'0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)', margin: '1px', maxWidth:'540px', minWidth:'326px', padding:'0', width:'99.375%', width:'-webkit-calc(100% - 2px)', width:'calc(100% - 2px)'}}>
                          <source src="img/video/identityv.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <h4>&ensp;第五人格活動</h4>
                    <p className="mb-3 color2">20支短影音，<strong style={{color:'#50c6be'}}>總累積1,018萬觀看</strong>，誕生一破100萬爆款短影音。</p>
                    <span></span>
                  </div>
                  </a> </div>
              <div className="col-md-6 col-lg-4 wow zoomIn" data-wow-delay="0.1s"> <a href="https://www.tiktok.com/@identityv_tw?is_from_webapp=1&sender_device=pc">
                  <div className="p-4">
                    <div className="mb-3" style={{width: 'auto'}}> 
                        <video controls="true" controlslist="nodownload" style={{background:'#FFF', border:'0', borderRadius:'3px', boxShadow:'0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)', margin: '1px', maxWidth:'540px', minWidth:'326px', padding:'0', width:'99.375%', width:'-webkit-calc(100% - 2px)', width:'calc(100% - 2px)'}}>
                          <source src="img/video/identityv_2.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <h4>&ensp;第五人格Tiktok頻道</h4>
                    <p className="mb-3 color2"></p>
                    <span></span>
                  </div>
                  </a> </div>
              <div className="col-md-6 col-lg-4 wow zoomIn" data-wow-delay="0.3s">
                  <div className="p-4">
                    <div className="mb-3" style={{width: 'auto'}}> 
                        <video controls="true" controlslist="nodownload" style={{background:'#FFF', border:'0', borderRadius:'3px', boxShadow:'0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)', margin: '1px', maxWidth:'540px', minWidth:'326px', padding:'0', width:'99.375%', width:'-webkit-calc(100% - 2px)', width:'calc(100% - 2px)'}}>
                          <source src="img/video/knivesout.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <h4>&ensp;荒野行動五週年</h4>
                    <p className="mb-3 color2">趣味拍攝荒野行動五週年生日祝賀，<strong style={{color:'#50c6be'}}>總累積觀看251萬</strong>，觀看達成率167% +。</p>
                  </div>
              </div>
              <div className="col-md-6 col-lg-4 wow zoomIn" data-wow-delay="0.3s">
                  <div className="p-4">
                    <div className="mb-3" style={{width: 'auto'}}> 
                        <video controls="true" controlslist="nodownload" style={{background:'#FFF', border:'0', borderRadius:'3px', boxShadow:'0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)', margin: '1px', maxWidth:'540px', minWidth:'326px', padding:'0', width:'99.375%', width:'-webkit-calc(100% - 2px)', width:'calc(100% - 2px)'}}>
                          <source src="img/video/lifeafter.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <h4>&ensp;明日之後守衛方舟市哨點</h4>
                    <p className="mb-3 color2">17位KOL結合線下活動，<strong style={{color:'#50c6be'}}>總累積觀看490萬</strong>。</p>
                  </div>
              </div>
              <div className="col-md-6 col-lg-4 wow zoomIn" data-wow-delay="0.3s">
                  <div className="p-4">
                    <div className="mb-3" style={{width: 'auto'}}> 
                        <video controls="true" controlslist="nodownload" style={{background:'#FFF', border:'0', borderRadius:'3px', boxShadow:'0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)', margin: '1px', maxWidth:'540px', minWidth:'326px', padding:'0', width:'99.375%', width:'-webkit-calc(100% - 2px)', width:'calc(100% - 2px)'}}>
                          <source src="img/video/lifeafter_2.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <h4>&ensp;明日之後xEVANGELION聯動</h4>
                    <p className="mb-3 color2">遊戲聯動結合創意特殊妝8位KOL，<strong style={{color:'#50c6be'}}>總累積觀看200萬</strong>。</p>
                  </div>
              </div>
              <div className="col-md-6 col-lg-4 wow zoomIn" data-wow-delay="0.5s">
                  <div className="p-4">
                    <div className="mb-3" style={{width: 'auto'}}> 
                        <video controls="true" controlslist="nodownload" style={{background:'#FFF', border:'0', borderRadius:'3px', boxShadow:'0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)', margin: '1px', maxWidth:'540px', minWidth:'326px', padding:'0', width:'99.375%', width:'-webkit-calc(100% - 2px)', width:'calc(100% - 2px)'}}>
                          <source src="img/video/lifeafter_3.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <h4>&ensp;明日之後線下快閃店</h4>
                    <p className="mb-3 color2">遊戲改版，線下超寫實遊戲體驗快閃店，適配多位KOLs到現場體驗活動，拍攝符合遊戲內容的創意短影音，達到趣味又自然的推廣效果，<strong style={{color:'#50c6be'}}>總觀看次數490萬</strong>。</p>
                  </div>
              </div>
        </div>
      </div></div>
    </Fragment>
  );
};

export default VideoWorkIndex;
