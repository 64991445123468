import React, { Fragment } from "react";

const Nav = ({active}) => {
  let activeArray = ["","","","",""];
  activeArray[active] = "active";
  return (
    <Fragment>
      <div className="container-fluid bg-warning px-5">
        <div className="row gx-4 d-none d-lg-flex">
            <div className="py-1"></div>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0 px-4 px-lg-5"> 
        <a className="navbar-brand" href="/"></a>
        <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"> <span className="navbar-toggler-icon"></span> </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto py-4 py-lg-0"> 
                <a href="/" className={`nav-item nav-link ${activeArray[0]}`}><span>首頁</span></a> 
                <a href="/about" className={`nav-item nav-link ${activeArray[1]}`}><span>關於呼聲</span></a> 
                <a href="/service" className={`nav-item nav-link ${activeArray[2]}`}><span>服務項目</span></a>
                <a href="/videowork" className={`nav-item nav-link ${activeArray[3]}`}><span>短影音行銷</span></a> 
                <a href="/work" className={`nav-item nav-link ${activeArray[4]}`}><span>精選案例</span></a> 
                <a href="/#koltt" className="nav-item nav-link"><span>獨家合作</span></a>
                <a href="/#kol" className="nav-item nav-link"><span>網紅合作</span></a> 
                <a href="/#contact" className="nav-item nav-link"><span>聯絡我們</span></a> 
            </div>
        </div>
      </nav>
    </Fragment>
  );
};

export default Nav;
