import React, { useEffect } from "react";
import $ from 'jquery';
window.jQuery = window.$ = $;
require('owl.carousel2'); 

const CarouselBox = () => {
  useEffect(() => {
    
    $(".header-carousel").owlCarousel({
      autoplay: true,
      smartSpeed: 1500,
      items: 1,
      dots: true,
      loop: true,
      nav : true,
      navText : [
          '<i class="bi bi-chevron-left"></i>',
          '<i class="bi bi-chevron-right"></i>'
      ]
    });

  }, []);
  return (
    <div className="container-fluid p-0 pb-5">
      <div className="owl-carousel header-carousel position-relative">
          <div className="owl-carousel-item position-relative"> <img className="img-fluid" src="img/carousel-1.jpg" alt="" />
            <div className="carousel-inner">
                <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-12 col-lg-8 text-center">
                        <h1 className="display-3 text-warning animated slideInDown mb-4">全方位整合行銷，360度服務最立體、最高效！</h1>
                        <p className="fs-6 text-white">針對需求與目標，擬定策略、創意到企劃執行、KOL安排、公關活動、媒體與廣告投放等，提供全盤優質服務，讓各環節的宣傳資源使用到位。</p>
                        <a href="/#contact" className="btn btn-warning rounded-pill py-md-3 px-md-5 me-3 animated pulse infinite"><span>聯絡呼聲</span></a> </div>
                  </div>
                </div>
            </div>
          </div>
          <div className="owl-carousel-item position-relative"> <img className="img-fluid" src="img/carousel-2.jpg" alt="" />
            <div className="carousel-inner">
                <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-12 col-lg-8 text-center">
                        <h1 className="display-3 text-warning animated slideInDown mb-4">最懂生態；最精準的KOL操作規劃！</h1>
                        <p className="fs-6 text-white">深厚的實戰經驗，提供各平台創意內容規劃、人選媒合，一條龍快速且精準的執行，觸及人數、影片觀看等曝光成效成本，幫您層層把關。</p>
                        <a href="/service" className="btn btn-warning rounded-pill py-md-3 px-md-5 me-3 animated pulse infinite">服務項目</a> </div>
                  </div>
                </div>
            </div>
          </div>
          <div className="owl-carousel-item position-relative"> <img className="img-fluid" src="img/carousel-3.jpg" alt="" />
            <div className="carousel-inner">
                <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-12 col-lg-8 text-center">
                        <h1 className="display-3 text-warning animated slideInDown mb-4">短影音專家，品牌行銷高效解方</h1>
                        <p className="fs-6 text-white">短影音浪潮來襲，TikTok、IG reels、YouTube SHORTS，懂得快速應用，有效的創意內容規劃，為品牌創造高聲量，達成企業推廣目標</p>
                        <a href="/videowork" className="btn btn-warning rounded-pill py-md-3 px-md-5 me-3 animated pulse infinite">短影音</a> </div>
                  </div>
                </div>
            </div>
          </div>
      </div>
    </div>
  );
};

export default CarouselBox;
