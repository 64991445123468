import React, { Fragment } from "react";

const BlankIndex = () => {
  return (
    <Fragment>      
      <div className="container-fluid page-header py-5 mb-5">
        <div className="container py-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">404 Error</h1>
            <nav aria-label="breadcrumb animated slideInDown">
              <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a className="text-white" href="/">首頁</a></li>
                  <li className="breadcrumb-item text-white active" aria-current="page">404 Error</li>
              </ol>
            </nav>
        </div>
      </div>

      <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container text-center">
            <div className="row justify-content-center">
              <div className="col-lg-6"> <i className="bi bi-exclamation-triangle display-1 text-primary"></i>
                  <h1 className="display-1">404</h1>
                  <h1 className="mb-4">Page Not Found</h1>
                  <p className="mb-4">很抱歉，您要查找的頁面在我們的網站上不存在！也許去我們的主頁或嘗試使用搜索？</p>
                  <a className="btn btn-primary rounded-pill py-3 px-5" href="/">回到首頁</a> </div>
            </div>
        </div>
      </div>
      <br /><br /><br /><br /><br />
    </Fragment>
  );
};

export default BlankIndex;
