import React from "react";

const Cooperation = () => {
  return (
    <div className="container-fluid py-5 brand">
        <div className="container">
            <div className="text-center">
                <div className="bg-primary mb-3 mx-auto" style={{width: '60px', height: '2px'}}></div>
                <h1 className="display-5 mb-5">合作品牌</h1>
            </div>
            <div className="row g-0 service-row">
                <div className="col-md-3 col-lg-2 col-sm-6 wow bounceIn" data-wow-delay="0.1s">
                    <div className="p-4 btn-square-logo mb-4" style={{ width: "auto" }}> <img className="img-fluid" src="img/logos/logo-CUB.png" alt="" /> </div>
                </div>
                <div className="col-md-3 col-lg-2 col-sm-6 wow bounceIn" data-wow-delay="0.3s">
                    <div className="p-4 btn-square-logo mb-4" style={{ width: "auto" }}> <img className="img-fluid" src="img/logos/logo-glenfiddich.png" alt="" /> </div>
                </div>
                <div className="col-md-3 col-lg-2 col-sm-6 wow bounceIn" data-wow-delay="0.5s">
                    <div className="p-4 btn-square-logo mb-4" style={{ width: "auto" }}> <img className="img-fluid" src="img/logos/logo-gq.png" alt="" /> </div>
                </div>
                <div className="col-md-3 col-lg-2 col-sm-6 wow bounceIn" data-wow-delay="0.7s">
                    <div className="p-4 btn-square-logo  mb-4" style={{ width: "auto" }}> <img className="img-fluid" src="img/logos/logo-vogue.png" alt="" /> </div>
                </div>
                <div className="col-md-3 col-lg-2 col-sm-6 wow bounceIn" data-wow-delay="0.9s">
                    <div className="p-4 btn-square-logo mb-4" style={{ width: "auto" }}> <img className="img-fluid" src="img/logos/logo-nike.png" alt="" /> </div>
                </div>
                <div className="col-md-3 col-lg-2 col-sm-6 wow bounceIn" data-wow-delay="1.1s">
                    <div className="p-4 btn-square-logo mb-4" style={{ width: "auto" }}> <img className="img-fluid" src="img/logos/logo-lv.png" alt="" /> </div>
                </div>
                <div className="col-md-3 col-lg-2 col-sm-6 wow bounceIn" data-wow-delay="1.3s">
                    <div className="p-4 btn-square-logo mb-4" style={{ width: "auto" }}> <img className="img-fluid" src="img/logos/logo-fp.png" alt="" /> </div>
                </div>
                <div className="col-md-3 col-lg-2 col-sm-6 wow bounceIn" data-wow-delay="1.5s">
                    <div className="p-4 btn-square-logo mb-4" style={{ width: "auto" }}> <img className="img-fluid" src="img/logos/logo-ct.png" alt="" /> </div>
                </div>
                <div className="col-md-3 col-lg-2 col-sm-6 wow bounceIn" data-wow-delay="1.7s">
                    <div className="p-4 btn-square-logo mb-4" style={{ width: "auto" }}> <img className="img-fluid" src="img/logos/logo-vme.png" alt="" /> </div>
                </div>
                <div className="col-md-3 col-lg-2 col-sm-6 wow bounceIn" data-wow-delay="1.9s">
                    <div className="p-4 btn-square-logo mb-4" style={{ width: "auto" }}> <img className="img-fluid" src="img/logos/logo-delicioso.png" alt="" /> </div>
                </div>
                <div className="col-md-3 col-lg-2 col-sm-6 wow bounceIn" data-wow-delay="2.1s">
                    <div className="p-4 btn-square-logo mb-4" style={{ width: "auto" }}> <img className="img-fluid" src="img/logos/logo-ccfun.png" alt="" /> </div>
                </div>
                <div className="col-md-3 col-lg-2 col-sm-6 wow bounceIn" data-wow-delay="2.3s">
                    <div className="p-4 btn-square-logo mb-4" style={{ width: "auto" }}> <img className="img-fluid" src="img/logos/logo-ro.png" alt="" /> </div>
                </div>
                <div className="col-md-3 col-lg-2 col-sm-6 wow bounceIn" data-wow-delay="2.5s">
                    <div className="p-4 btn-square-logo mb-4" style={{ width: "auto" }}> <img className="img-fluid" src="img/logos/logo-echo.png" alt="" /> </div>
                </div>
                <div className="col-md-3 col-lg-2 col-sm-6 wow bounceIn" data-wow-delay="2.7s">
                    <div className="p-4 btn-square-logo mb-4" style={{ width: "auto" }}> <img className="img-fluid" src="img/logos/logo-awake.png"alt="" /> </div>
                </div>
                <div className="col-md-3 col-lg-2 col-sm-6 wow bounceIn" data-wow-delay="2.9s">
                    <div className="p-4 btn-square-logo mb-4" style={{ width: "auto" }}> <img className="img-fluid" src="img/logos/logo-identity.png" alt="" /> </div>
                </div>
                <div className="col-md-3 col-lg-2 col-sm-6 wow bounceIn" data-wow-delay="3.1s">
                    <div className="p-4 btn-square-logo mb-4" style={{ width: "auto" }}> <img className="img-fluid" src="img/logos/logo-knives.png" alt="" /> </div>
                </div>
                <div className="col-md-3 col-lg-2 col-sm-6 wow bounceIn" data-wow-delay="3.3s">
                    <div className="p-4 btn-square-logo mb-4" style={{ width: "auto" }}> <img className="img-fluid" src="img/logos/logo-lifeafter.png" alt="" /> </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Cooperation;
