import React, { Fragment } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import HomeIndex from "./pages/Home/HomeIndex";
import AboutIndex from "./pages/About/AboutIndex";
import ServiceIndex from "./pages/Service/ServiceIndex";
import VideoWorkIndex from "./pages/VideoWork/VideoWorkIndex";
import WorkIndex from "./pages/Work/WorkIndex";
import BlankIndex from "./pages/Blank/BlankIndex";

import Nav from "./components/Nav";
import Footer from "./components/Footer";

function App() {
  return (
    <Router>
      <Fragment>
        
          
          <Switch>
            <Route exact path="/">
              <Nav active={0} />
              <HomeIndex />
            </Route>
            
            <Route exact path="/about">
              <Nav active={1} />
              <AboutIndex />
            </Route>

            <Route exact path="/service">
              <Nav active={2} />
              <ServiceIndex />
            </Route>

            <Route exact path="/videowork">
              <Nav active={3} />
              <VideoWorkIndex />
            </Route>

            <Route exact path="/work">
              <Nav active={4} />
              <WorkIndex />
            </Route>

            <Route path="*" exact={true}>
              <Nav />
              <BlankIndex />
            </Route>
          </Switch>
          <Footer />


        <div id="gotop"></div>
      </Fragment>
    </Router>
  );
}

export default App;
